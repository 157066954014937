import { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react';
import { Badge, Dropdown, Table } from 'react-bootstrap';
import cx from 'classnames';
import { TextInput } from 'components/form';
import { useBreakpoints, useTranslation } from 'hooks';
import styles from './UserTable.module.scss';
import { Text } from 'components';
import UserType from 'types/user.type';
import PaginationBar from 'components/pagination';
import { generatePath, useHistory } from 'react-router-dom';
import Paths from 'constants/Paths';
import { OktaUserStatus, UserStatus } from 'constants/User';
import { toOktaUserStatuses, toUserStatus } from 'helpers/userStatus';
import { UserFilter } from '../UserManagementPage';
import { BP } from 'hooks/useBreakpoints';
import handleError from 'helpers/handleError';

const NEXT_PAGE_OFFSET = 8;
const ITEMS_PER_PAGE = 10;

interface UserTableProps extends ComponentPropsWithoutRef<'div'> {
  fetchUsers: (filters: UserFilter, abortController?: AbortController) => Promise<void>;
  filters: UserFilter;
  users: UserType[];
  after?: string;
}

function UserTable({ fetchUsers, filters, users, after, ...props }: Readonly<UserTableProps>) {
  const [status, setStatus] = useState<UserStatus>(UserStatus.All);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const { breakpoint } = useBreakpoints();
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  const totalPage = Math.ceil(users.length / ITEMS_PER_PAGE);
  const startCount = page * ITEMS_PER_PAGE;
  const dateFormat = new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const filterDeps = useRef<UserFilter>(filters);
  filterDeps.current = filters;
  useEffect(() => {
    const abortController = new AbortController();
    const timer = window.setTimeout(() => {
      setLoading(true);
      setPage(0);
      handleError(
        fetchUsers(
          {
            ...filterDeps.current,
            status: toOktaUserStatuses(status),
            after: undefined,
            search,
          },
          abortController
        ).finally(() => setLoading(false))
      );
    }, 200);
    return () => {
      window.clearTimeout(timer);
      abortController.abort();
    };
  }, [fetchUsers, search, status]);

  return (
    <div {...props} className={cx('d-flex flex-column', props.className)}>
      <TextInput
        className={cx(styles.search, 'd-flex flex-column-reverse')}
        description={t('user_table.search_help_text')}
        control={{
          onChange: (e) => setSearch(e.currentTarget.value),
          placeholder: t('user_table.search_placeholder'),
          value: search,
        }}
        icon={{
          left: {
            alt: t('user_table.search_placeholder'),
            name: 'searchBlack',
            height: 16,
            width: 16,
          },
        }}
      />
      <Text>
        {t('user_table.search_results', {
          end: Math.min(startCount + ITEMS_PER_PAGE, users.length),
          plus: after ? '+' : '',
          total: users.length,
          start: startCount,
        })}
      </Text>
      <Table className={styles.table} borderless hover>
        <thead className={styles.header}>
          <tr>
            <td className={cx(styles.col, styles['col--name'])} colSpan={2}>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <span className="my-2">{t('user_table.name')}</span>
                <Dropdown
                  onSelect={async (newStatus) => {
                    if (!newStatus) return;
                    setStatus(newStatus as UserStatus);
                  }}
                >
                  <Dropdown.Toggle className={cx(styles.dropdown, 'py-1 px-2')}>
                    {t('status')}: {t(`user_table.filters.${status}`)}{' '}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.values(UserStatus).map((userStatus) => (
                      <Dropdown.Item
                        active={userStatus === status}
                        eventKey={userStatus}
                        key={userStatus}
                      >
                        {t(`user_table.filters.${userStatus}`)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {users.slice(startCount, startCount + ITEMS_PER_PAGE).map((user) => {
            const lastLogin = user.lastLogin
              ? dateFormat.format(new Date(user.lastLogin))
              : t('user_table.no_sign_in');
            const userStatus = toUserStatus(OktaUserStatus[user.status]);
            return (
              <tr
                onClick={() =>
                  history.push(generatePath(Paths.userManagementViewUserInfo, { id: user.id }))
                }
                className={styles.user}
                key={user.id}
                tabIndex={0}
                role="button"
              >
                <td className={cx(styles.col, styles['col--name'], 'py-4')}>
                  <Text className={styles.truncate} type="h6" tag="h3">
                    {user.profile.firstName} {user.profile.lastName}
                  </Text>
                  <Text className={styles.truncate} color="cf-dark-grey" tag="p" type="bodySm">
                    {user.profile.email}
                  </Text>
                </td>
                <td className={cx(styles.col, styles['col--status'], 'py-3')}>
                  <Text align="right" tag="p" type="bodySm">
                    <strong>{t('user_table.last_sign_in')}:</strong> {lastLogin}
                  </Text>
                  <Badge className={cx(styles.status, styles[`status--${userStatus}`])}>
                    {t(`okta.user_statuses.${user.status}`)}
                  </Badge>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <PaginationBar
        className="align-self-center mt-4"
        totalPages={totalPage}
        onPageChange={(nextPage) => {
          if (nextPage + NEXT_PAGE_OFFSET >= totalPage && after && !loading) {
            setLoading(true);
            fetchUsers({ ...filters, after }).then(() => setLoading(false));
          }
          setPage(nextPage);
        }}
        currentPage={page}
        numsOfButtons={breakpoint > BP.XS ? 5 : 3}
      />
    </div>
  );
}

export default UserTable;
