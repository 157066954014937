import { useFormik } from 'formik';
import * as Validator from 'helpers/validator';
import ApiError from 'lib/errors/ApiError';

export enum FormLabels {
  oldPassword = 'oldPassword',
  passwordNew = 'passwordNew',
  passwordConf = 'passwordConf',
}

export type EditUserFormData = {
  [FormLabels.oldPassword]: string;
  [FormLabels.passwordNew]: string;
  [FormLabels.passwordConf]: string;
};

type ChangePasswordPayload = {
  oldPassword: { value: string };
  newPassword: { value: string };
};

type FormErrors = Partial<Record<FormLabels, Validator.ValidationError | null>>;

function validateForm(data: EditUserFormData): FormErrors {
  const errors: FormErrors = {};

  if (Validator.validatePassword(data.oldPassword)) {
    errors.oldPassword = Validator.validatePassword(data.oldPassword);
  }

  if (Validator.validateNewPassword(data.oldPassword, data.passwordNew)) {
    errors.passwordNew = Validator.validateNewPassword(data.passwordNew, data.passwordNew);
  }

  if (Validator.validatePasswordConf(data.passwordNew, data.passwordConf)) {
    errors.passwordConf = Validator.validatePasswordConf(data.passwordNew, data.passwordConf);
  }

  return errors;
}

function useChangePasswordForm(params: {
  changePassword: (passwordData: ChangePasswordPayload) => Promise<number>;
  beforeSubmit(): void;
  onSuccess(): void;
  onFailure(): void;
}) {
  const form = useFormik<EditUserFormData>({
    enableReinitialize: true,
    initialValues: {
      [FormLabels.oldPassword]: '',
      [FormLabels.passwordNew]: '',
      [FormLabels.passwordConf]: '',
    },
    validateOnMount: true,
    validate: (values) => validateForm(values),
    onSubmit: async (values) => {
      params.beforeSubmit?.();
      try {
        const passwordData = {
          oldPassword: {
            value: values.oldPassword,
          },
          newPassword: {
            value: values.passwordNew,
          },
        };

        await params.changePassword(passwordData);

        params.onSuccess();
      } catch (err: unknown) {
        if (err instanceof ApiError && err.status !== 500) {
          const apiError = err;

          if (apiError.status === 400) {
            const errors: Record<string, string> = {};
            if (apiError.details) {
              apiError.details.forEach((detail) => {
                if (detail.param) {
                  errors[detail.param] = detail.msg;
                }
              });
            }
            form.setErrors(errors);
          }
        } else {
          params.onFailure && params.onFailure();
        }
      }
    },
  });

  return form;
}

export default useChangePasswordForm;
