import { HTMLAttributes } from 'react';
import cx from 'classnames';
import Icon from 'components/rp-icon/rp-icon';
import imageMap from 'constants/IconUrlMap';
import Color from 'types/color.type';
import styles from './inline-actions.module.scss';

export interface ActionProps extends HTMLAttributes<HTMLButtonElement> {
  icon: keyof typeof imageMap;
  label: string;
  color: Color;
}

export interface InlineActionsProp extends HTMLAttributes<HTMLUListElement> {
  actions: ActionProps[];
}

function InlineActions({ actions, ...props }: Readonly<InlineActionsProp>) {
  return (
    <ul
      {...props}
      className={cx('d-flex flex-wrap align-items-center', props.className, styles.container)}
    >
      {actions.map((itemProps, i, { length }) => (
        <li className="d-flex align-items-center" key={i}>
          <button
            type="button"
            {...itemProps}
            className={cx(
              'py-1 px-2',
              props.className,
              styles[`action--${itemProps.color}`],
              styles.action
            )}
          >
            <Icon
              name={itemProps.icon}
              alt={itemProps.label}
              className="mr-2"
              width="18"
              ariaHidden
            />
            <span>{itemProps.label}</span>
          </button>
          {length - 1 !== i && <div className={cx('ml-2', styles.divider)} aria-hidden />}
        </li>
      ))}
    </ul>
  );
}

export default InlineActions;
