/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useOktaAuth } from '@okta/okta-react';
import { toast } from 'react-toastify';
import { useTranslation } from 'hooks';
import useEditProfileForm, { FormLabels } from './useEditProfileForm';
import UserType from 'types/user.type';
import UnitType from 'types/unit.type';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import { ModalType } from 'constants/ModalType';
import { useModalContext } from 'context/modal-context';
import { useAppContext } from 'context/app-context';
import { useAuthContext } from 'context/auth-context';
import { UnitService, PropertyService, RetailerGroupService, UserService } from 'services';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { Text, TextField, Layout, PillContainer, CustomButton, Button } from 'components';
import { StoreSelect } from 'components/select';
import handleError from 'helpers/handleError';

import styles from './Profile.module.scss';
import { PortalRole } from 'constants/User';
import { keyBy } from 'lodash-es';

function Profile() {
  const { t } = useTranslation();
  const { isUserMgmt, portalRole } = useAuthContext();
  const { setAndShowModal, setShowModal } = useModalContext();
  const { locale } = useAppContext();
  const { oktaAuth } = useOktaAuth();
  const accessTokenObj = useOktaAuth()?.authState?.accessToken;
  const { uid } = accessTokenObj.claims;
  const { accessToken } = accessTokenObj;

  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<UserType>();
  const [retailerGroups, setRetailerGroups] = useState<RetailerGroup[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [unitMap, setUnitMap] = useState<Record<string, UnitType>>({});

  const editProfileForm = useEditProfileForm({
    userData: user?.profile,
    updateUser: (userData) => {
      return handleError(UserService.editMyProfile(userData, accessToken)) as Promise<UserType>;
    },
    onSuccess: async (user) => {
      toast.success(t('user_info_panel.edit_success'));
      setIsEditing(false);
      setUser(user);
      if (user.profile.portalRole === PortalRole.RetailClient && user.profile.units.length === 0) {
        await handleError(UserService.deactivate(uid, accessToken));
        toast.success(t('user_info_panel.deactivate_warning_success'));
        oktaAuth.signOut();
      }
    },
  });

  useEffect(() => {
    const processRetailerGroups = (user: UserType, groups: RetailerGroup[]) => {
      const filteredRetailGroups = groups.filter(
        (group) => group.sys && user.profile.retailerGroups?.includes(group.sys.id)
      );
      setRetailerGroups(filteredRetailGroups);
    };
    const processUnits = (user: UserType, units: UnitType[]) => {
      setUser({
        ...user,
        profile: {
          ...user.profile,
          stores: units,
        },
      });
      setUnitMap((unitMap) => ({ ...unitMap, ...keyBy(units, 'sys.id') }));

      RetailerGroupService.getAllRetailerGroups(accessToken).then((groups) =>
        processRetailerGroups(user, groups)
      );

      PropertyService.getAllProperties(accessToken).then((allProperties) => {
        setProperties(allProperties);
      });
    };
    const processUserProfile = (user: UserType) => {
      if (user?.profile?.units) {
        UnitService.getUnitsById(user.profile.units, accessToken).then((units) =>
          processUnits(user, units)
        );
      } else {
        setUser({
          ...user,
          profile: {
            ...user.profile,
            stores: [],
          },
        });
      }
    };
    const userServicePromise = UserService.getMyProfile(accessToken).then(processUserProfile);
    handleError(userServicePromise);
  }, [accessToken, uid]);

  const showDeletionWarningModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setAndShowModal({
      type: ModalType.Generic,
      title: t('user_info_panel.deletion_warning_title'),
      body: t('user_info_panel.deletion_warning_body'),
      handleClose: () => setShowModal(false),
      handleAccept: () => {
        UserService.delete(uid, accessToken).then(
          () => {
            toast.success(t('user_info_panel.deletion_warning_success'));
            setShowModal(false);
            oktaAuth.signOut();
          },
          (e: unknown) => {
            if (e instanceof Error) {
              toast.error(e.message);
            }
          }
        );
      },
      confirmButtonText: t('user_info_panel.deletion_warning_confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  const renderEditStores = () => (
    <Container fluid className="mt-2 px-0">
      <StoreSelect
        label={`${t('search_for_stores')} ${count}`}
        onChange={(stores) => {
          editProfileForm.setFieldValue(
            FormLabels.stores,
            stores.map((store) => unitMap[store])
          );
        }}
        control={{
          value: editProfileForm.values.stores.map((store) => store.sys.id),
          name: FormLabels.stores,
        }}
        getUnitsByRetailerGroup={async (retailerGroup) => {
          if (!retailerGroup?.sys?.id) return [];
          const units = await UnitService.getUnitsByRetailer(retailerGroup.sys.id, accessToken);
          setUnitMap((unitMap) => ({
            ...unitMap,
            ...keyBy(units, 'sys.id'),
          }));
          return units;
        }}
        getUnitsByProperty={async (property) => {
          if (!property?.sys?.id) return [];
          const units = await UnitService.getUnitsByProperty(property.sys.id, accessToken);
          setUnitMap((unitMap) => ({
            ...unitMap,
            ...keyBy(units, 'sys.id'),
          }));
          return units;
        }}
        getUnitById={(unitId) => unitMap[unitId]}
        retailerGroups={retailerGroups}
        properties={properties}
      />
    </Container>
  );

  const renderModalButtons = () => (
    <>
      <CustomButton
        variant="cf-text"
        iconName="circularArrow"
        alt=""
        text={t('change_password.prompt')}
        customStyle={styles.changePassword}
        iconWidth="15"
        iconHeight="15"
        onClick={showChangePasswordModal}
      />
      <CustomButton
        variant="cf-text"
        iconName="archiveRed"
        alt=""
        text={t('remove_account.prompt')}
        customStyle={styles.removeAccount}
        iconWidth="15"
        iconHeight="15"
        onClick={showDeletionWarningModal}
      />
    </>
  );

  const selectedStoreCount = editProfileForm.values.stores.length;
  const count = selectedStoreCount ? `(${selectedStoreCount})` : '';

  const showChangePasswordModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setAndShowModal({
      type: ModalType.ChangePassword,
      title: t('change_password_modal.title'),
      handleClose: () => setShowModal(false),
      confirmButtonText: t('change_password_modal.confirm_button'),
      cancelButtonText: t('cancel'),
    });
  };

  return (
    <Layout
      pageTitle={t('metadata:myProfilePage.pageTitle')}
      pageDescription={t('metadata:myProfilePage.description')}
      showFooterNav
      showHeaderNav
      showTapestryRight
      showTapestryOnMobile
      tapestryName="tapestryTop"
    >
      <Container className={styles.root}>
        {user ? (
          <form onSubmit={editProfileForm.handleSubmit}>
            <Text color="cf-dark-grey" type="allCaps" tag="p">
              {t(isUserMgmt ? 'admin_profile' : 'user_profile')}
            </Text>
            <Text type="h2" tag="h1" typeMobile="h1">
              {t('my_profile')}
            </Text>
            <div className={styles.editProfile}>
              <div className={styles.wrapper}>
                <label className={styles.label}>
                  {isEditing ? (
                    <TextField
                      label={FormLabels.firstName}
                      value={editProfileForm.values.firstName}
                      error={
                        editProfileForm.touched.firstName ? editProfileForm.errors.firstName : ''
                      }
                      onChange={editProfileForm.handleChange}
                      onBlur={editProfileForm.handleBlur}
                    />
                  ) : (
                    <>
                      <Text color="cf-dark-grey" tag="h2">
                        {t('form.firstName')}
                      </Text>
                      <Text className={styles.input} tag="p">
                        {user.profile.firstName}
                      </Text>
                    </>
                  )}
                </label>
                <label className={styles.label}>
                  {isEditing ? (
                    <TextField
                      label={FormLabels.lastName}
                      value={editProfileForm.values.lastName}
                      error={
                        editProfileForm.touched.lastName ? editProfileForm.errors.lastName : ''
                      }
                      onChange={editProfileForm.handleChange}
                      onBlur={editProfileForm.handleBlur}
                    />
                  ) : (
                    <>
                      <Text color="cf-dark-grey" tag="h2">
                        {t('form.lastName')}
                      </Text>
                      <Text className={styles.input} tag="p">
                        {user.profile.lastName}
                      </Text>
                    </>
                  )}
                </label>
              </div>
              <Text color="cf-dark-grey" tag="h2">
                {t('form.email')}
              </Text>
              <Text color={isEditing ? 'cf-dark-grey' : 'black'} className={styles.input} tag="p">
                {user.profile.email}
              </Text>
              {portalRole === PortalRole.RetailClient && (
                <div className={isEditing ? styles.storesEdit : styles.stores}>
                  {isEditing ? (
                    renderEditStores()
                  ) : (
                    <>
                      <Text type="body" tag="h2" color="cf-dark-grey" className={styles.input}>
                        {`${t('stores')} ${count}`}
                      </Text>
                      <PillContainer
                        units={user.profile.stores as UnitType[]}
                        locale={locale}
                        className="px-0"
                        showCloseIcon={false}
                      />
                    </>
                  )}
                </div>
              )}
              {isEditing && renderModalButtons()}
            </div>
            {isEditing ? (
              <>
                <Button
                  type="submit"
                  variant="solid"
                  disabled={!editProfileForm.dirty}
                  className={cx(styles.button, styles.save)}
                >
                  {t('save_changes')}
                </Button>
                <Button
                  variant="outline"
                  className={styles.button}
                  onClick={(e) => {
                    editProfileForm.handleReset(e);
                    setIsEditing(false);
                  }}
                >
                  {t('cancel')}
                </Button>
              </>
            ) : (
              <Button
                variant="outline"
                className={styles.button}
                onClick={() => setIsEditing(true)}
              >
                {t('edit_profile')}
              </Button>
            )}
          </form>
        ) : (
          <div className={styles.loader}>
            <Spinner animation="border" variant="dark" />
          </div>
        )}
      </Container>
    </Layout>
  );
}

export default Profile;
