import { Button, PasswordField, PasswordPolicy, Text } from 'components';
import useSetPasswordForm, { FormLabels } from './useSetPasswordForm';
import { useTranslation } from 'hooks';
import { toast } from 'react-toastify';
import { SetPasswordState, SetPasswordPageState } from '../SetPasswordPage';
import { SetStateAction, Dispatch } from 'react';
import styles from './SetPasswordForm.module.scss';

interface SetPasswordFormProps {
  setSuccess: Dispatch<SetStateAction<SetPasswordState>>;
  stateToken: string;
  page: SetPasswordPageState;
}

function SetPasswordForm({ setSuccess, stateToken, page }: Readonly<SetPasswordFormProps>) {
  const { t } = useTranslation();
  const form = useSetPasswordForm({
    stateToken,
    beforeSubmit: () => {
      const invalidFields = Object.keys(form.errors);
      if (invalidFields.length > 0) {
        const target = document.getElementById(invalidFields[0]);
        if (target) {
          target.focus();
          target.scrollIntoView();
        }
      }
    },
    onFailure: () => toast.error(t('error:general_description')),
    onSuccess: () => {
      setSuccess(SetPasswordState.success);
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <div className={styles.form}>
        <Text type="body" color="cf-dark-grey">
          <PasswordField
            id={FormLabels.passwordNew}
            label={FormLabels.passwordNew}
            value={form.values.passwordNew}
            error={form.touched.passwordNew ? form.errors.passwordNew : ''}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            ariaDescribedBy="password-policy"
          />
          <PasswordField
            id={FormLabels.passwordConf}
            label={FormLabels.passwordConf}
            value={form.values.passwordConf}
            error={form.touched.passwordConf ? form.errors.passwordConf : ''}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </Text>
        <div id="password-policy">
          <PasswordPolicy />
        </div>
        <Button className="mt-4" type="submit" size="stretch">
          {t(`${page}.submit`)}
        </Button>
      </div>
    </form>
  );
}

export default SetPasswordForm;
