import cx from 'classnames';
import styles from './radio-button.module.scss';

function RadioButton(props: Readonly<RadioButtonProps>) {
  const { containerClassName, className, ...otherProps } = props;
  return (
    <div className={cx(styles.container, containerClassName)}>
      <input className={cx(styles.radio, className)} type="radio" {...otherProps} />
    </div>
  );
}

export interface RadioButtonProps {
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  containerClassName?: string;
  className?: string;
  id?: string;
  name?: string;
  required?: boolean;
  readOnly?: boolean;
  tabIndex?: number;
}

export default RadioButton;
