import { ComponentPropsWithoutRef, useState } from 'react';
import cx from 'classnames';
import PaginationBar from 'components/pagination';
import { Text, Icon, Button } from 'components';
import { useBreakpoints, useTranslation } from 'hooks';
import styles from './UserRequests.module.scss';
import UserType from 'types/user.type';
import { BP } from 'hooks/useBreakpoints';
import { Link, generatePath } from 'react-router-dom';
import Paths from 'constants/Paths';

interface UserRequestsProps extends ComponentPropsWithoutRef<'div'> {
  userRequests: UserType[];
}

const itemsPerPage = 5;

function UserRequests({ userRequests, ...props }: Readonly<UserRequestsProps>) {
  const requestList = Object.values(userRequests);
  const totalRequests = requestList.length;
  const { breakpoint } = useBreakpoints();
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const dateFormat = new Intl.DateTimeFormat('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const showLoadMore = breakpoint < BP.S;
  const skip = page * itemsPerPage;

  return (
    <div
      {...props}
      className={cx('d-flex flex-column flex-align-center p-4', styles.container, props.className)}
    >
      <Text tag="h2" type="h4">
        {t(`user_request_table.${totalRequests ? '' : 'no_requests.'}title`)}
        {!!totalRequests && (
          <Icon
            className="ml-3"
            name="exclamation"
            height="24"
            width="24"
            alt="Has pending requests"
          />
        )}
      </Text>
      <Text tag="p" type="bodyLg">
        {t('user_request_table.description', { count: totalRequests })}
      </Text>
      <ul className={cx(styles.requests, 'd-flex flex-column mt-4 p-0')}>
        {requestList.slice(showLoadMore ? 0 : skip, skip + itemsPerPage).map((request) => {
          const date = new Date(request.created);
          return (
            <li
              className={cx(
                styles.request,
                'd-flex justify-content-between align-items-center py-3'
              )}
              key={request.id}
            >
              <div className={cx(styles.requestInfo, 'flex-shrink-1')}>
                <Text className={styles.truncate} tag="h3" type="h5">
                  {request.profile.firstName} {request.profile.lastName}
                </Text>
                <Text className={styles.truncate} color="cf-dark-grey" tag="p" type="body">
                  {request.profile.email}
                </Text>
              </div>
              <div className="flex-shrink-0">
                <Text align="right" tag="p" type="bodySm">
                  <strong>Requested:</strong> {dateFormat.format(date)}
                </Text>
                <Link
                  className={cx(
                    styles.action,
                    'd-inline-flex justify-content-end align-items-center p-0'
                  )}
                  to={generatePath(Paths.userManagementViewUserRequest, { id: request.id })}
                >
                  <Text type="h7" color="cf-blue" tag="p" align="right">
                    View Details
                  </Text>
                  <Icon className="ml-1" name="caretBlue" alt="caret icon" height="10" width="10" />
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
      {showLoadMore ? (
        <Button variant="outline" onClick={() => setPage(page + 1)}>
          {t('user_request_table.load')}
        </Button>
      ) : (
        <PaginationBar
          totalPages={Math.ceil(requestList.length / itemsPerPage)}
          className="align-self-center mt-4"
          numsOfButtons={itemsPerPage}
          onPageChange={setPage}
          currentPage={page}
        />
      )}
    </div>
  );
}

export default UserRequests;
