import Icon from '../rp-icon/rp-icon';
import Text from '../text/text';
import styles from './pill-button.module.scss';

function PillButton({ text, label, onIconClick }: Readonly<PillButtonProps>) {
  return (
    <div className={styles.pill}>
      <Text type="bodySm">{text}</Text>
      {onIconClick && (
        <button
          className={styles.closeButton}
          aria-label={label}
          onClick={(e) => {
            e.preventDefault();
            onIconClick();
          }}
          type="button"
        >
          <Icon className={styles.closeIcon} name="closeOutlined" width="16" height="16" alt="" />
        </button>
      )}
    </div>
  );
}

export interface PillButtonProps {
  text: string;
  label?: string;
  onIconClick?: (() => void) | null;
}

export default PillButton;
