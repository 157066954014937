import Layout from 'components/layout/layout';
import { useTranslation, useRouter } from 'hooks';
import { Button, Text } from 'components';
import styles from './SetPasswordPage.module.scss';
import SetPasswordForm from './SetPasswordForm/SetPasswordForm';
import { useState, useEffect } from 'react';
import { UserService } from 'services';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';

export enum SetPasswordState {
  initial = 'form',
  success = 'success',
  error = 'error',
}

export enum SetPasswordPageState {
  activate = 'activateAccount',
  reset = 'resetPassword',
}

const SetPasswordPage = ({ page }: { page: SetPasswordPageState }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { handleLoading } = useFullPageLoaderContext();
  const [stateToken, setStateToken] = useState('');
  const [state, setState] = useState<SetPasswordState>(SetPasswordState.initial);

  const recoveryToken = router.queries.get('recoveryToken') ?? '';

  useEffect(() => {
    handleLoading(
      UserService.verifyRecoveryToken(recoveryToken)
        .then((response) => setStateToken(response.stateToken))
        .catch((e) => {
          if (e instanceof Error) {
            console.error(e);
            setState(SetPasswordState.error);
          }
        })
    );
  }, [handleLoading, recoveryToken]);

  return (
    <Layout
      pageTitle={t(`metadata:${page}Page.pageTitle`)}
      pageDescription={t(`metadata:${page}Page.description`)}
      showTapestryLeft
      backgroundColor="pale-blue"
    >
      <div className={styles.setPasswordPage}>
        <div className={styles.widgetWrapper}>
          {state === SetPasswordState.initial ? (
            <>
              <Text type="h2" tag="h1" typeMobile="h1" className={styles.heading}>
                {t(`${page}.title`)}
              </Text>
              <SetPasswordForm page={page} setSuccess={setState} stateToken={stateToken} />
            </>
          ) : (
            <div>
              <div>
                <Text type="h2" tag="h1" typeMobile="h1" className={styles.heading}>
                  {t(`${page}.${state}.title`)}
                </Text>
                <Text color="cf-dark-grey" tag="p" className="mb-5">
                  {t(`${page}.${state}.description`)}
                </Text>
              </div>
              <Button
                type="button"
                size="stretch"
                label={t(`${page}.back`)}
                onClick={router.toLoginPage}
              >
                {t(`${page}.back`)}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default SetPasswordPage;
