/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from 'components/layout/layout';
import OfferTabs from 'components/offer-tabs/offer-tabs';
import CustomButton from 'components/custom-button/custom-button';
import SearchBox from 'components/search-box/search-box';
import styles from './BrowseOffersPage.module.scss';
import Paths from 'constants/Paths';
import Container from 'react-bootstrap/Container';
import { useBreakpoints, useTranslation } from 'hooks';
import { SearchCategory } from 'helpers/analytics';
import { useAppContext } from 'context/app-context';

function BrowseOffersPage() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const { analyticsInstance } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Layout
      pageTitle={t('metadata:browseOffersPage.pageTitle')}
      pageDescription={t('metadata:browseOffersPage.description')}
      showFooterNav
      showHeaderNav
    >
      <Container>
        <div className={styles['search-section']}>
          <SearchBox
            onChange={(val) => {
              setSearchTerm(val);
              analyticsInstance?.sendSearchEvent(val, SearchCategory.OFFER);
            }}
          />
          <Link to={Paths.createOfferPage}>
            <CustomButton
              variant="cf-primary"
              iconName="plus"
              alt="plus icon"
              text={t('add_offer')}
              customStyle={isMobile ? 'mt-2' : ''}
            />
          </Link>
        </div>

        <div className={styles.main}>
          <OfferTabs searchTerm={searchTerm} />
        </div>
      </Container>
    </Layout>
  );
}

export default BrowseOffersPage;
