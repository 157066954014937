import { TextareaHTMLAttributes, useRef } from 'react';
import cx from 'classnames';
import InputLayout, { getAriaTags, InputLayoutDtoProps } from '../input-layout';
import styles from './textarea-input.module.scss';

export interface TextareaInputProps extends InputLayoutDtoProps {
  control: TextareaHTMLAttributes<HTMLTextAreaElement>;
}

function TextareaInput({ control, ...props }: Readonly<TextareaInputProps>) {
  const layoutRef = useRef<HTMLElement>(null);
  const ariaTags = getAriaTags(layoutRef.current, props);

  return (
    <InputLayout {...props} required={control.required} ref={layoutRef}>
      <textarea
        {...control}
        {...ariaTags}
        className={cx(props.error && 'is-invalid', control.className, styles.input)}
      />
    </InputLayout>
  );
}

export default TextareaInput;
