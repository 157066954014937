import { useFormik } from 'formik';
import Locale from 'constants/Locale';
import * as Validator from 'helpers/validator';
import UserService from 'services/user';
import ApiError from 'lib/errors/ApiError';
import UnitType from 'types/unit.type';
import { useOktaAuth } from '@okta/okta-react';
import UserType from 'types/user.type';

export enum FormLabels {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  langPref = 'langPref',
  stores = 'stores',
}

export type CreateUserForm = {
  [FormLabels.firstName]: string;
  [FormLabels.lastName]: string;
  [FormLabels.email]: string;
  [FormLabels.langPref]: Locale;
  [FormLabels.stores]: UnitType[];
};

type FormErrors = Partial<Record<FormLabels, Validator.ValidationError | null>>;

function validateForm(data: CreateUserForm): FormErrors {
  const errors: FormErrors = {};

  if (Validator.validateFirstName(data.firstName)) {
    errors.firstName = Validator.validateFirstName(data.firstName);
  }

  if (Validator.validateLastName(data.lastName)) {
    errors.lastName = Validator.validateLastName(data.lastName);
  }

  if (Validator.validateEmail(data.email)) {
    errors.email = Validator.validateEmail(data.email);
  }

  if (Validator.validateStores(data.stores)) {
    errors.stores = Validator.validateStores(data.stores);
  }

  return errors;
}

function useCreateUserForm(params: {
  beforeSubmit?(data: CreateUserForm): void;
  onSuccess?(data: UserType): void;
  onFailure?(): void;
  afterSubmit?(data: CreateUserForm): void;
}) {
  const { accessToken } = useOktaAuth()?.authState?.accessToken || {};
  const form = useFormik<CreateUserForm>({
    initialValues: {
      [FormLabels.firstName]: '',
      [FormLabels.lastName]: '',
      [FormLabels.email]: '',
      [FormLabels.langPref]: Locale.en,
      [FormLabels.stores]: [],
    },
    validateOnMount: true,
    validate: (values) => validateForm(values),
    onSubmit: async (values) => {
      try {
        if (params.beforeSubmit) {
          params.beforeSubmit(values);
        }
        const unitIds = values.stores.map((v) => v.sys.id);

        const result = await UserService.createUser(
          {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            preferredLanguage: values.langPref,
            units: unitIds,
          },
          accessToken
        );

        if (params.onSuccess) {
          params.onSuccess(result);
        }
      } catch (err: unknown) {
        if (err instanceof ApiError && err.status === 400) {
          const apiError = err;
          const errors: Record<string, string> = {};
          if (apiError.details) {
            apiError.details.forEach((detail) => {
              if (detail.param) {
                errors[detail.param] = detail.msg;
              }
            });
          }
          form.setErrors(errors);
        } else {
          params.onFailure && params.onFailure();
        }
      } finally {
        if (params.afterSubmit) {
          params.afterSubmit(values);
        }
      }
    },
  });

  return form;
}

export default useCreateUserForm;
