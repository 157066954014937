import limits from '../helpers/validation/limits';

export default {
  any: {
    regexp: {
      pattern: '[\\s\\S]+$',
    },
  },
  /**
   * Disallows email aliases e.g., test+cadillac@cadillacfairview.com
   */
  email: {
    regexp: {
      pattern: '^\\w[\\w.-]*@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$',
    },
  },
  /**
   * More relaxed validation which allows for email aliases e.g., test+cadillac@cadillacfairview.com
   */
  emailRelaxed: {
    regexp: {
      pattern: '^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}$',
    },
  },
  hyphenatedCharacters: {
    regexp: {
      pattern: '^[A-Za-z0-9_-]*$',
    },
    message:
      'This field can only contain letters (A-Za-z), numbers (0-9), hyphens (-) and underscores (_).',
  },
  hours: {
    regexp: {
      pattern: '^(0[0-9]|1[012]):[0-5][0-9]\\s[AP]M-(0[0-9]|1[012]):[0-5][0-9]\\s[AP]M$|^Closed$',
    },
    message: 'Format: "09:00 AM-09:00 PM", or "Closed"',
  },
  hoursFr: {
    regexp: {
      pattern: '^(0?[0-9]|1[0-9]|2[0-3])h[0-5][0-9]-(0?[0-9]|1[0-9]|2[0-3])h[0-5][0-9]$|^Fermé$',
    },
    message: 'Format : « 09h00-21h00 », ou « Fermé »',
  },
  name: {
    regexp: {
      pattern:
        "^[A-Za-zàÀâÂçÇéÉèÈêÊëËîÎïÏôÔûÛùÙüÜÿŸñÑæÆœŒ]+((('|-)?([A-Za-zàÀâÂçÇéÉèÈêÊëËîÎïÏôÔûÛùÙüÜÿŸñÑæÆœŒ])+))*((( )?([A-Za-zàÀâÂçÇéÉèÈêÊëËîÎïÏôÔûÛùÙüÜÿŸñÑæÆœŒ])+((('|-)?([A-Za-zàÀâÂçÇéÉèÈêÊëËîÎïÏôÔûÛùÙüÜÿŸñÑæÆœŒ])+))*))?$",
    },
    limits: limits.characterLimit(64),
  },
  number: {
    regexp: {
      pattern: '^-?[0-9]*$',
    },
  },
  phoneNumber: {
    regexp: {
      pattern: '^[0-9]?[ -.]?\\(?[0-9]{3}\\)?[ -.]?[0-9]{3}[ -.]?[0-9]{4}$',
    },
  },
  relativeURL: {
    regexp: {
      pattern: '^[a-zA-Z0-9\\/]+(?:-[a-zA-Z0-9\\/]+)*$',
      flags: null,
    },
    message: 'Please input a valid Relative URL',
  },
  websiteRegex: {
    regexp: {
      pattern:
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?:/(?:\\S)*)?$',
      flags: null,
    },
    message: 'Please input a valid Website URL',
  },
  relativeOrWebsiteRegex: {
    regexp: {
      pattern:
        '^((http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-]))?|((#|\\/)([0-9a-zA-Z#_!:.?+=&%@!\\-]*))*)$|^[a-zA-Z0-9\\/]+(?:-[a-zA-Z0-9\\/]+)*$',
      flags: null,
    },
    message: 'Please input a valid Website URL or Relative URL',
  },
  slug: {
    regexp: {
      pattern: '^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$',
      flags: null,
    },
    message:
      'The value must be a valid slug ( Alpha numeric characters separated by at most a single dash)',
  },
};
