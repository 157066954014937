import styles from './shimmer.module.scss';

function Shimmer(props: Readonly<ShimmerProps>) {
  const cssHeight = props.height ? `${props.height}px` : 'auto';
  const cssWidth = props.width ? `${props.width}px` : '100%';

  return <div className={styles.root} style={{ height: cssHeight, width: cssWidth }} />;
}

interface ShimmerProps {
  height?: number;
  width?: number;
}

export default Shimmer;
