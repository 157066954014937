import { useFormik } from 'formik';
import * as Validator from 'helpers/validator';
import { UserService } from 'services';
import ApiError from 'lib/errors/ApiError';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';

export enum FormLabels {
  passwordNew = 'passwordNew',
  passwordConf = 'passwordConf',
}

export type SetPasswordFormData = {
  [FormLabels.passwordNew]: string;
  [FormLabels.passwordConf]: string;
};

type FormErrors = Partial<Record<FormLabels, Validator.ValidationError | null>>;

function validateForm(data: SetPasswordFormData): FormErrors {
  const errors: FormErrors = {};

  if (Validator.validatePassword(data.passwordNew)) {
    errors.passwordNew = Validator.validatePassword(data.passwordNew);
  }

  if (data.passwordConf !== data.passwordNew) {
    errors.passwordConf = Validator.ValidationError.misMatch;
  }

  return errors;
}

function useSetPasswordForm(params: {
  stateToken: string;
  beforeSubmit?(): void;
  afterSubmit?(): void;
  onFailure?(): void;
  onSuccess?(data: boolean): void;
}) {
  const { handleLoading } = useFullPageLoaderContext();

  const onError = (err: unknown) => {
    if (err instanceof ApiError) {
      const apiError = err;

      if (apiError.status === 400 && err.status !== 500) {
        const errors: Record<string, string> = {};
        apiError.details &&
          apiError.details.forEach((detail) => {
            if (detail.param) {
              errors[detail.param] = detail.msg;
            }
          });

        form.setErrors(errors);
      }
    } else {
      params.onFailure && params.onFailure();
    }
  };

  const processValues = async (values: SetPasswordFormData) => {
    try {
      if (params.beforeSubmit) {
        params.beforeSubmit();
      }

      if (params.onSuccess) {
        await UserService.resetPasswordWithToken(params.stateToken, values.passwordNew);
        params.onSuccess(true);
      }
    } catch (err: unknown) {
      onError(err);
    } finally {
      if (params.afterSubmit) {
        params.afterSubmit();
      }
    }
  };

  const form = useFormik<SetPasswordFormData>({
    initialValues: {
      [FormLabels.passwordNew]: '',
      [FormLabels.passwordConf]: '',
    },
    validateOnMount: true,
    validate: (values) => validateForm(values),
    onSubmit: (values) => handleLoading(processValues(values)),
  });

  return form;
}

export default useSetPasswordForm;
