import { Link } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import NavItemType from 'constants/NavItemTypes';
import NavItem from 'types/NavItem';
import styles from './header.module.scss';
import cx from 'classnames';

function DesktopHeader({
  navItems,
  locale,
  toggleLocale,
  displayNav,
  homeRedirect,
}: Readonly<DesktopHeaderProps>) {
  const { t } = useTranslation();

  return (
    <nav className={styles.headerContainer}>
      <Link to={homeRedirect} tabIndex={0}>
        <img src="/images/cf-logo.png" alt="cf-logo" width="180" height="40" />
      </Link>

      <div className={styles.headerNav}>
        {displayNav && (
          <>
            {navItems.map((v, i) => {
              if (v.type === NavItemType.link) {
                const to = v.to as string;
                return (
                  <Link
                    key={i}
                    to={to}
                    className={cx(styles.navLink, { [styles.active]: v.active })}
                    tabIndex={0}
                  >
                    {t(v.value)}
                  </Link>
                );
              } else if (v.type === NavItemType.button) {
                return (
                  <button
                    key={i}
                    className={cx(styles.navLink, styles.button, { [styles.active]: v.active })}
                    onClick={v.onClick}
                    tabIndex={0}
                  >
                    {t(v.value)}
                  </button>
                );
              }
              return <></>;
            })}

            <div className={styles.navDivider} />
          </>
        )}

        <button className={`${styles.localeButton} ${styles.button}`} onClick={toggleLocale}>
          {locale}
        </button>
      </div>
    </nav>
  );
}
export interface DesktopHeaderProps {
  navItems: NavItem[];
  toggleLocale: () => void;
  locale: string;
  displayNav: boolean;
  homeRedirect: string;
}

export default DesktopHeader;
