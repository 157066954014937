import { Trans } from 'react-i18next';
import { useTranslation } from '../../hooks';
import { useAppContext } from 'context/app-context';
import termsAndPolicyUrls from 'constants/TermsAndPolicyUrls';
import { Link } from 'react-router-dom';
import { Text } from 'components';
import styles from './LoginPage.module.scss';
import Paths from 'constants/Paths';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import { REACT_APP_CF_HELP_EMAIL } from 'config/env';

export const BottomLinks = () => {
  const { t } = useTranslation();
  const { setAndShowModal, setShowModal } = useModalContext();

  const showHelpModal = () =>
    setAndShowModal({
      type: ModalType.Generic,
      title: t('signIn.help_title'),
      body: (
        <>
          {t('signIn.help_body')}
          <br />
          <a
            href={`mailto:${REACT_APP_CF_HELP_EMAIL}`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.termsLink}
          >
            {REACT_APP_CF_HELP_EMAIL}
          </a>
          <br />
          <a
            href={t('help_modal.learn_more_url')}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.termsLink}
          >
            {t('help_modal.learn_more')}
          </a>
        </>
      ),
      handleClose: () => setShowModal(false),
    });

  return (
    <div className={styles.helpLinks}>
      <Text tag="div" type="body" className={styles.terms} color="cf-dark-grey">
        {t('signIn.links.signupText')}{' '}
        <Link to={Paths.signupPage} className={styles.termsLink}>
          {t('signIn.links.signupLink')}
        </Link>
      </Text>
      <Text tag="div" type="body" className={styles.terms}>
        <button onClick={showHelpModal} className={styles.termsLink}>
          {t('signIn.links.help')}
        </button>
      </Text>
    </div>
  );
};

const LoginLinks = () => {
  const { t } = useTranslation();
  const { locale } = useAppContext();

  const tos = (
    <a
      href={termsAndPolicyUrls.tos[locale]}
      className={styles.termsLink}
      target="_blank"
      rel="noreferrer"
    >
      {t('terms_of_service')}
    </a>
  );
  const privacy = (
    <a
      href={termsAndPolicyUrls.privacy[locale]}
      className={styles.termsLink}
      target="_blank"
      rel="noreferrer"
    >
      {t('privacy_policy')}
    </a>
  );

  return (
    <div className={styles.forgotPasswordLinks}>
      <Text type="bodySm" typeMobile="body" color="cf-dark-grey" className={styles.terms}>
        <Trans i18nKey="signIn.links.disclaimer">
          {' '}
          {tos} {privacy}
        </Trans>
      </Text>
      <Text type="bodySm" typeMobile="body" className={styles.terms}>
        <Link to={Paths.forgotPassword} className={styles.termsLink}>
          {t('signIn.links.forgot')}
        </Link>
      </Text>
    </div>
  );
};
export default LoginLinks;
