import { useState, useRef } from 'react';
import { TextInputProps } from '../text-input';
import InputLayout, { getAriaTags } from '../input-layout';
import InputField from '../input-field';
import InputToken from '../input-token';
import { TypedInputElement } from 'types/global.type';

export interface TokenInputProps extends TextInputProps {
  onChange?: (newValue: string[]) => void;
  control: TypedInputElement<HTMLInputElement, string[]>;
}

function TokenInput({ className, onChange, control, ...props }: Readonly<TokenInputProps>) {
  const [value, setValue] = useState('');
  const layoutRef = useRef<HTMLElement>(null);
  const ariaTags = getAriaTags(layoutRef.current, props);

  return (
    <InputLayout {...props} required={control.required} ref={layoutRef}>
      <InputField
        error={!!props.error}
        control={{
          ...control,
          ...ariaTags,
          onKeyDown: (e) => {
            if (e.key !== 'Enter') return;
            e.preventDefault();

            if (!!value && !control.value.includes(value)) {
              onChange?.([...control.value, value]);
            }
            setValue('');
          },
          onChange: (e) => {
            setValue(e.target.value);
          },
          value,
        }}
        icon={props.icon}
      />
      <InputToken readOnly={control.disabled} tokens={control.value} onChange={onChange} />
    </InputLayout>
  );
}

export default TokenInput;
