import InputField from '../input-field';
import cx from 'classnames';
import { ReactNode, useRef } from 'react';
import InputLayout, { getAriaTags, InputLayoutDtoProps } from '../input-layout';
import styles from './datetime-input.module.scss';
import { TypedInputElement } from 'types/global.type';
import { FormControl } from 'react-bootstrap';

function parseValue(dateStr: string) {
  const [date, tokens] = (dateStr ?? '').split('T');
  const time = (tokens ?? '').split(':').slice(0, 2).join(':');
  return [date ?? '', time ?? ''];
}

export interface DateTimeInputProps extends InputLayoutDtoProps {
  control: TypedInputElement<HTMLInputElement, string>;
  errors?: {
    date?: ReactNode;
    time?: ReactNode;
  } | null;
  onChange: (dateStr: string) => void;
  type: 'datetime' | 'date' | 'time';
}

function DateTimeInput({
  control,
  onChange: _onChange,
  type,
  errors,
  ...props
}: Readonly<DateTimeInputProps>) {
  const useDate = type.includes('date');
  const useTime = type.includes('time');
  const layoutRef = useRef(null);
  const ariaTags = getAriaTags(layoutRef.current, props);
  const [date, time] = parseValue(control.value);

  // Validate final output before returning to consumer
  const onChange = (newValue: string) => {
    if (newValue === 'T') newValue = '';
    _onChange?.(newValue);
  };

  return (
    <InputLayout {...props} required={control?.required} ref={layoutRef}>
      <div className={styles.container}>
        {useDate && (
          <div className={cx(styles.date, !!errors?.date && styles.hasError)}>
            <InputField
              error={!!errors?.date || !!props.error}
              control={{
                ...ariaTags,
                onBlur: control?.onBlur,
                disabled: control?.disabled,
                name: control?.name,
                onChange(e) {
                  if (e.currentTarget.value === '') {
                    return onChange(`${useTime ? 'T' : ''}${time}`);
                  }
                  onChange(`${e.currentTarget.value}${useTime ? 'T' : ''}${time}`);
                },
                'aria-errormessage': errors?.date
                  ? `${ariaTags.id}-date--error`
                  : ariaTags['aria-errormessage'],
                'aria-invalid': !!errors?.date || !!props.error,
                value: date,
                type: 'date',
              }}
            />
            {errors?.date && (
              <FormControl.Feedback
                id={`${ariaTags.id}-date--error`}
                className={styles.error}
                type="invalid"
              >
                {errors?.date}
              </FormControl.Feedback>
            )}
          </div>
        )}
        {useTime && (
          <div className={cx(styles.time, !!errors?.time && styles.hasError)}>
            <InputField
              error={!!errors?.time || !!props.error}
              control={{
                ...ariaTags,
                onBlur: control?.onBlur,
                disabled: control?.disabled,
                name: control?.name,
                onChange(e) {
                  onChange(`${date}${useDate ? 'T' : ''}${e.currentTarget.value}`);
                },
                'aria-errormessage': errors?.time
                  ? `${ariaTags.id}-time--error`
                  : ariaTags['aria-errormessage'],
                'aria-invalid': !!errors?.time || !!props.error,
                value: time,
                type: 'time',
              }}
            />
            {errors?.time && (
              <FormControl.Feedback
                id={`${ariaTags.id}-time--error`}
                className={styles.error}
                type="invalid"
              >
                {errors?.time}
              </FormControl.Feedback>
            )}
          </div>
        )}
      </div>
    </InputLayout>
  );
}

export default DateTimeInput;
