import { linkToFile } from 'components/form/image-input';
import { getChangedFields, getDatetimeString } from 'helpers/form';
import { omitBy, isNil, isEmpty } from 'lodash-es';
import { Offer, OfferData } from 'types';
import { OfferState, OfferType } from './useOfferForm';
import * as Validator from 'helpers/validator';
import UnitType from 'types/unit.type';

type DateTimeFields = 'startDateTime' | 'endDateTime' | 'publishDateTime';
export type OfferStateErrors = Partial<
  {
    [field in Exclude<keyof OfferState, DateTimeFields>]: Validator.ValidationError | null;
  } & {
    [dateField in DateTimeFields]: Partial<{
      date: Validator.ValidationError | null;
      time: Validator.ValidationError | null;
    } | null>;
  }
>;

export function getInitValues() {
  return {
    isMultilingual: false,
    startDateTime: '',
    endDateTime: '',
    publishDateTime: '',
    type: OfferType.storewide,
    locations: [],
    categories: [],
    keywords: [],
    keywordsFrench: [],
    headline: '',
    headlineFrench: '',
    description: '',
    descriptionFrench: '',
    image: null,
    imageFrench: null,
    promotionCode: '',
  };
}

export async function validateOfferForm(
  values: OfferState,
  savedOffer?: Offer,
  unitMap: Record<string, UnitType> = {}
) {
  const formMultilingual = values.isMultilingual;
  const [startDate, startTime] = values.startDateTime.split('T');
  const [endDate, endTime] = values.endDateTime.split('T');
  const [publishDate, publishTime] = values.publishDateTime.split('T');

  const startDateTimeErrors = {
    date: Validator.validateStartDate(startDate),
    time: Validator.validateStartTime(startTime, startDate),
  };
  const endDateTimeErrors = {
    date: Validator.validateEndDate(endDate, startDate),
    time: Validator.validateEndTime(endTime, startTime, endDate, startDate),
  };
  const publishDateTimeErrors = {
    date: Validator.validatePublishDate(publishDate, startDate, endDate),
    time: Validator.validatePublishTime(
      endTime,
      startTime,
      publishTime,
      publishDate,
      endDate,
      startDate
    ),
  };

  let errors: OfferStateErrors = {
    startDateTime: isEmpty(omitBy(startDateTimeErrors, isNil)) ? null : startDateTimeErrors,
    endDateTime: isEmpty(omitBy(endDateTimeErrors, isNil)) ? null : endDateTimeErrors,
    publishDateTime: isEmpty(omitBy(publishDateTimeErrors, isNil)) ? null : publishDateTimeErrors,
    locations:
      Validator.validateLocations(values.locations) ??
      Validator.validateOfferLocale(values.locations, unitMap, formMultilingual),
    categories: values.categories?.length < 1 ? Validator.ValidationError.missing : null,
    keywords: Validator.validateKeywords(
      values.keywords,
      values.type === OfferType.productSpecific
    ),
    headline: Validator.validateHeadline(values.headline),
    description: Validator.validateDescription(values.description),
    image: await Validator.validateEntryImageFile(values.image, true),
    promotionCode: Validator.validatePromotionCode(values.promotionCode, false),
  };

  if (formMultilingual) {
    errors = {
      ...errors,
      headlineFrench: Validator.validateHeadline(values.headlineFrench),
      descriptionFrench: Validator.validateDescription(values.descriptionFrench),
      keywordsFrench: Validator.validateKeywords(
        values.keywordsFrench,
        values.type === OfferType.productSpecific
      ),
      imageFrench: await Validator.validateEntryImageFile(values.imageFrench, true),
    };
  }

  return errors;
}

// Convert server payload to state
export async function transformServerOffer(offer: Offer): Promise<Partial<OfferState>> {
  return omitBy(
    {
      isMultilingual: offer.hasFrench,
      startDateTime: offer.startDateTime ? getDatetimeString(new Date(offer.startDateTime)) : null,
      endDateTime: offer.endDateTime ? getDatetimeString(new Date(offer.endDateTime)) : null,
      publishDateTime: offer.publishDateTime
        ? getDatetimeString(new Date(offer.publishDateTime))
        : null,
      type: offer.type as OfferType,
      locations: offer.locations,
      categories: offer.categories,
      keywords: offer.keywords?.['en-CA'],
      keywordsFrench: offer.keywords?.['fr-CA'],
      headline: offer.headline?.['en-CA'],
      headlineFrench: offer.headline?.['fr-CA'],
      description: offer.description?.['en-CA'],
      descriptionFrench: offer.description?.['fr-CA'],
      image: offer.imageUrl?.['en-CA']
        ? await linkToFile(offer.imageUrl?.['en-CA'], `[${offer.sys.id}] Offer Image`)
        : undefined,
      imageFrench: offer.imageUrl?.['fr-CA']
        ? await linkToFile(offer.imageUrl?.['fr-CA'], `[${offer.sys.id}] French Offer Image`)
        : undefined,
      promotionCode: offer.promotionCode,
    },
    isNil
  );
}

// Convert state to server payload
export function toServerPayload(
  { __action, ...offer }: OfferState,
  initialValues: OfferState
): Partial<OfferData> {
  const changedFields = getChangedFields(Object.keys(getInitValues()), initialValues, offer);
  const data = { ...offer };

  // Remove french info (if applicable) if not mutlilingual
  if (!data.isMultilingual) {
    if (initialValues.headlineFrench) data.headlineFrench = '';
    if (initialValues.descriptionFrench) data.descriptionFrench = '';
    if (initialValues.keywordsFrench) data.keywordsFrench = [];
    if (initialValues.imageFrench) {
      changedFields.imageFrench = null;
      data.imageFrench = null;
    }
  }

  // Data transformations - START
  const {
    image,
    imageFrench,
    isMultilingual,
    startDateTime,
    endDateTime,
    publishDateTime,
    ..._payload
  } = data;
  const payload: Partial<OfferData> = { ..._payload };

  // Remove incomplete datetime values
  if ('startDateTime' in data) {
    const d = new Date(startDateTime);
    payload.startDateTime = isNaN(d.getTime()) ? undefined : d.toISOString();
  }
  if ('endDateTime' in data) {
    const d = new Date(endDateTime);
    payload.endDateTime = isNaN(d.getTime()) ? undefined : d.toISOString();
  }
  if ('publishDateTime' in data) {
    const d = new Date(publishDateTime);
    payload.publishDateTime = isNaN(d.getTime()) ? undefined : d.toISOString();
  }

  // Apply new images if needed
  if ('imageFrench' in changedFields) payload.fileFrench = imageFrench;
  if ('image' in changedFields) payload.fileEnglish = image;

  return omitBy(payload, (v) => v === '' || v === undefined);
}
