import { useRef, CSSProperties, InputHTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import InputLayout, { getAriaTags, InputLayoutDtoProps } from '../input-layout';
import styles from './radio-group-input.module.scss';

export interface RadioGroupInputProps extends InputLayoutDtoProps {
  control: Omit<InputHTMLAttributes<HTMLInputElement>, 'children'>;
  columns: number;
  options: {
    label: ReactNode;
    value: string;
  }[];
}

function RadioGroupInput({ control, columns, options, ...props }: Readonly<RadioGroupInputProps>) {
  const layoutRef = useRef<HTMLElement>(null);
  const ariaTags = getAriaTags(layoutRef.current, props);
  return (
    <InputLayout {...props} required={control.required} ref={layoutRef} asFieldset>
      <div
        style={{ '--rg-cols': columns } as CSSProperties}
        aria-labelledby={ariaTags['aria-labelledby']}
        className={styles.container}
      >
        {options.map((option, key) => {
          const isChecked = option.value === control.value;
          return (
            <div className={styles.item} key={key}>
              <Form.Check
                {...control}
                {...option}
                className={cx(control.className, styles.input)}
                id={`${layoutRef.current?.id}--${key}`}
                checked={isChecked}
                type="radio"
                custom
              />
            </div>
          );
        })}
      </div>
    </InputLayout>
  );
}

export default RadioGroupInput;
