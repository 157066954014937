import { Dispatch, SetStateAction, createContext, useContext, useMemo } from 'react';
import ModalProvider from 'components/modal/modal-provider';
import useModal from 'hooks/useModal';
import { ModalProviderProps } from 'types/modal.type';

const ModalContext = createContext<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setAndShowModal: (props: ModalProviderProps) => void;
  modalProps: ModalProviderProps | null;
}>({
  showModal: false,
  setShowModal: () => {},
  setAndShowModal: () => {},
  modalProps: null,
});

export function ModalContextProvider({ children }: Readonly<ModalContextProviderProps>) {
  const { showModal, setShowModal, setAndShowModal, modalProps } = useModal();
  const sharedState = useMemo(
    () => ({
      showModal,
      setShowModal,
      setAndShowModal,
      modalProps,
    }),
    [showModal, setShowModal, setAndShowModal, modalProps]
  );

  return (
    <ModalContext.Provider value={sharedState}>
      <ModalProvider />

      {children}
    </ModalContext.Provider>
  );
}

export interface ModalContextProviderProps {
  children?: React.ReactNode;
}

export function useModalContext() {
  return useContext(ModalContext);
}
