import React, { createContext, useContext } from 'react';
import Locale from 'constants/Locale';
import GoogleAnalyticsInitializer from 'helpers/analytics';

type AppContextType = {
  locale: Locale;
  setLocale: (locale: Locale, force?: boolean) => void;
  children?: React.ReactNode;
  analyticsInstance?: GoogleAnalyticsInitializer;
};

const Context = createContext<AppContextType>({
  locale: Locale.en,
  setLocale: () => {},
});

export const langToLocale = {
  fr: Locale.fr,
  en: Locale.en,
};

export function AppContextProvider({ children, ...sharedState }: Readonly<AppContextType>) {
  return <Context.Provider value={sharedState}>{children}</Context.Provider>;
}

export function useAppContext() {
  return useContext(Context);
}
