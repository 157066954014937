import { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useCreateUserForm, { FormLabels } from './useCreateUserForm';
import Locale from 'constants/Locale';
import useTranslation from 'hooks/useTranslation';
import styles from './CreateUserModal.module.scss';
import Property from 'types/property.type';
import RetailerGroup from 'types/retailerGroup.type';
import PropertyService from 'services/property';
import RetailerGroupService from 'services/retailerGroup';
import UnitType from 'types/unit.type';
import GenericModal from '../GenericModal';
import { Trans } from 'react-i18next';
import { ModalType } from 'constants/ModalType';
import { useModalContext } from 'context/modal-context';
import UserType from 'types/user.type';
import handleError from 'helpers/handleError';
import { errorToNoPronounError } from 'helpers/form';
import { SelectInput, TextInput } from 'components/form';
import { StoreSelect } from 'components/select';
import { UnitService } from 'services';
import { keyBy } from 'lodash-es';
import { useOktaAuth } from '@okta/okta-react';

function CreateUserModal({
  title,
  handleClose,
  confirmButtonText,
  cancelButtonText,
}: Readonly<CreateUserModalProps>) {
  const { t } = useTranslation();
  const { setAndShowModal, setShowModal } = useModalContext();
  const [unitMap, setUnitMap] = useState<Record<string, UnitType>>({});
  const accessToken = useOktaAuth().authState?.accessToken?.accessToken;

  const successModal = (userData: UserType) => {
    setAndShowModal({
      type: ModalType.Generic,
      title: (
        <Trans i18nKey={'create_user_modal.success_title'}>
          {''}
          <div className={styles.underline} />
        </Trans>
      ),
      handleAccept: () => setShowModal(false),
      confirmButtonText: t('create_user_modal.success_confirm'),
      body: <div>{t('create_user_modal.success_body', { email: userData.profile.email })}</div>,
    });
  };

  const createUserForm = useCreateUserForm({
    onSuccess: (userData) => {
      handleClose();
      successModal(userData);
    },
    onFailure: () => {
      handleClose();
      toast.error(t('error:general_description'));
    },
  });

  const [retailerGroups, setRetailerGroups] = useState<RetailerGroup[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);

  useEffect(() => {
    const controller = new AbortController();

    handleError(
      (async () => {
        if (!accessToken) return;
        const [groups, allProperties] = await Promise.all([
          RetailerGroupService.getAllRetailerGroups(accessToken, controller.signal),
          PropertyService.getAllProperties(accessToken, false, controller.signal),
        ]);
        if (!groups || !allProperties || controller.signal.aborted) return;
        setRetailerGroups(groups);
        setProperties(allProperties);
      })()
    );

    return () => {
      controller.abort();
    };
  }, [accessToken]);

  const selectedStoreCount = createUserForm.values.stores.length;

  const getError = (name: FormLabels) => {
    if (!createUserForm.touched[name]) return;

    const error = errorToNoPronounError(createUserForm.errors[name] as string);
    return error && t(`error:form.${name}.${error}`);
  };

  const count = selectedStoreCount ? `(${selectedStoreCount})` : '';

  return (
    <GenericModal
      show
      title={title}
      handleClose={handleClose}
      handleAccept={() => createUserForm.submitForm()}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
      fullWidthButtons
      props={{ backdrop: 'static' }}
    >
      <Container
        onSubmit={createUserForm.handleSubmit}
        className="px-0 border-top pt-4"
        as="form"
        fluid
      >
        <Row sm={2} xs={1}>
          <Col>
            <TextInput
              label={t(`form.${FormLabels.firstName}`)}
              error={getError(FormLabels.firstName)}
              control={{
                value: createUserForm.values.firstName,
                onChange: createUserForm.handleChange,
                onBlur: createUserForm.handleBlur,
                name: FormLabels.firstName,
              }}
            />
          </Col>

          <Col>
            <TextInput
              label={t(`form.${FormLabels.lastName}`)}
              error={getError(FormLabels.lastName)}
              control={{
                value: createUserForm.values[FormLabels.lastName],
                onChange: createUserForm.handleChange,
                onBlur: createUserForm.handleBlur,
                name: FormLabels.lastName,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              label={t(`form.${FormLabels.email}`)}
              error={getError(FormLabels.email)}
              control={{
                value: createUserForm.values[FormLabels.email],
                onChange: createUserForm.handleChange,
                onBlur: createUserForm.handleBlur,
                name: FormLabels.email,
                type: 'email',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectInput
              label={t('form.langPref')}
              items={[
                { label: t(Locale.en), value: Locale.en },
                { label: t(Locale.fr), value: Locale.fr },
              ]}
              control={{
                value: createUserForm.values[FormLabels.langPref],
                onChange: createUserForm.handleChange,
                onBlur: createUserForm.handleBlur,
                name: FormLabels.langPref,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StoreSelect
              label={`${t('search_for_stores')} ${count}`}
              getUnitById={(unitId) => unitMap[unitId]}
              retailerGroups={retailerGroups}
              properties={properties}
              getUnitsByRetailerGroup={async (retailerGroup) => {
                if (!retailerGroup?.sys?.id) return [];
                const units = await UnitService.getUnitsByRetailer(
                  retailerGroup.sys.id,
                  accessToken
                );
                setUnitMap((unitMap) => ({
                  ...unitMap,
                  ...keyBy(units, 'sys.id'),
                }));
                return units;
              }}
              getUnitsByProperty={async (property) => {
                if (!property?.sys?.id) return [];
                const units = await UnitService.getUnitsByProperty(property.sys.id, accessToken);
                setUnitMap((unitMap) => ({
                  ...unitMap,
                  ...keyBy(units, 'sys.id'),
                }));
                return units;
              }}
              control={{
                value: createUserForm.values[FormLabels.stores].map((store) => store.sys.id),
                name: FormLabels.stores,
              }}
              onChange={(stores) =>
                createUserForm.setFieldValue(
                  FormLabels.stores,
                  stores.map((store) => unitMap[store])
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </GenericModal>
  );
}

interface CreateUserModalProps {
  title: string;
  handleClose: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export default CreateUserModal;
