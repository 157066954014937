import { range } from 'lodash-es';
import { HTMLAttributes, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import cx from 'classnames';
import styles from './pagination.module.scss';

export interface PaginationBarProps extends HTMLAttributes<HTMLUListElement> {
  onPageChange: (page: number) => void;
  numsOfButtons?: number;
  currentPage: number;
  totalPages: number;
}

function PaginationBar({
  numsOfButtons = 3,
  onPageChange,
  currentPage,
  totalPages,
  ...props
}: Readonly<PaginationBarProps>) {
  const [start, end] = useMemo(() => {
    if (numsOfButtons >= totalPages) {
      return [0, totalPages];
    }

    const currRange = [currentPage, currentPage + 1];
    let capacity = numsOfButtons - 1;
    let idx = 0;

    // We expand the "breath" of the start until out of spots
    while (capacity) {
      if (currRange[idx] > 0 && currRange[idx] < totalPages) {
        currRange[idx] = currRange[idx] + (idx ? 1 : -1);
        capacity = capacity - 1;
      }
      idx = (idx + 1) % 2;
    }

    return currRange;
  }, [currentPage, totalPages, numsOfButtons]);

  return (
    <Pagination
      {...props}
      className={cx(props.className, styles.container, 'd-inline-flex align-items-center')}
    >
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 0}
        className={styles.arrow}
      />
      {range(start, end).map((idx) => (
        <Pagination.Item onClick={() => onPageChange(idx)} active={currentPage === idx} key={idx}>
          {idx + 1}
        </Pagination.Item>
      ))}
      {end < totalPages && <Pagination.Ellipsis className={styles.dots} disabled />}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages - 1}
        className={styles.arrow}
      />
    </Pagination>
  );
}

export default PaginationBar;
