import cx from 'classnames';
import styles from './text.module.scss';
import useBreakpoints from '../../hooks/useBreakpoints';
import Color from 'types/color.type';

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type BodyLevels = 'body' | 'bodySm' | 'bodyLg' | 'allCaps';
type TextTags = 'span' | 'div' | 'p' | 'blockquote' | 'cite' | HeadingLevels;
type TextAlignment = 'left' | 'center' | 'right';
type StyleTypes = HeadingLevels | BodyLevels | 'h7';

function Text({
  id,
  type = 'body',
  tag = 'span',
  align = 'left',
  color = 'midnight-blue',
  className,
  children,
  // Include only if mobile uses different levels of text; desktop will use type and tag above.
  typeMobile,
  tagMobile,
  highlightColor,
}: Readonly<TextProps>) {
  const { isMobile } = useBreakpoints();
  const TagName = tagMobile && isMobile ? tagMobile : tag;

  return (
    <TagName
      id={id}
      className={cx(
        styles.root,
        typeMobile && isMobile ? styles[`type-${typeMobile}`] : styles[`type-${type}`],
        {
          [styles[`align-${align}`]]: !!align,
          [styles[`color-${color}`]]: !!color,
          [styles[`highlight-${highlightColor}`]]: !!highlightColor,
        },
        className
      )}
    >
      {children}
    </TagName>
  );
}

interface TextProps {
  id?: string;
  type?: StyleTypes;
  tag?: TextTags;
  align?: TextAlignment;
  color?: Color;
  className?: string;
  children?: React.ReactNode;
  typeMobile?: StyleTypes;
  tagMobile?: TextTags;
  highlightColor?: Color;
}

export default Text;
