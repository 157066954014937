import { createContext, useContext, ReactNode, useEffect, useState, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Paths from 'constants/Paths';
import { PortalRole } from 'constants/User';
import { useAppContext } from './app-context';

type AuthContext = {
  isUserMgmt: boolean;
  isAuthenticated: boolean;
  homeRedirect: string;
  hasAuthState: boolean;
  token?: string;
  idToken?: string;
  portalRole: PortalRole | null;
};

const Context = createContext<AuthContext>({
  isUserMgmt: false,
  isAuthenticated: false,
  homeRedirect: '/',
  hasAuthState: false,
  portalRole: null,
});

export function AuthContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const { authState, oktaAuth } = useOktaAuth();
  const { setLocale } = useAppContext();
  const [homeRedirect, setHomeRedirect] = useState('/');
  const [isUserMgmt, setIsUserMgmt] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasAuthState, setHasAuthState] = useState(false);
  const [token, setToken] = useState<string>();
  const [idToken, setIdToken] = useState<string>();
  const [portalRole, setPortalRole] = useState<PortalRole | null>(null);

  useEffect(() => {
    if (authState) {
      const preferredLanguage = authState?.accessToken?.claims?.preferredLanguage;

      setHasAuthState(true);
      setIsAuthenticated(authState?.isAuthenticated);
      setIsUserMgmt(authState?.accessToken?.claims?.isUserMgmt);
      setToken(authState?.accessToken?.accessToken);
      setIdToken(authState?.idToken);
      setPortalRole(authState?.accessToken?.claims?.portalRole);

      if (authState.isAuthenticated) {
        if (authState?.accessToken?.claims?.isUserMgmt) {
          setHomeRedirect(Paths.userManagement);
        } else {
          setHomeRedirect(Paths.browseOffersPage);
        }
      }

      if (preferredLanguage) {
        setLocale(preferredLanguage);
      }
    }
  }, [authState, oktaAuth, setLocale]);

  const sharedState: AuthContext = useMemo(
    () => ({
      isUserMgmt,
      isAuthenticated,
      homeRedirect,
      hasAuthState,
      token,
      idToken,
      portalRole,
    }),
    [isUserMgmt, isAuthenticated, homeRedirect, hasAuthState, token, idToken, portalRole]
  );

  return <Context.Provider value={sharedState}>{children}</Context.Provider>;
}

export function useAuthContext() {
  return useContext(Context);
}
